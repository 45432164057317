<template>
	<data-loader :watch="{session,otchetnik}" :callback="loadData" :data.sync="data" @ready="onLoaded">
		<template #loading>
			<v-progress-linear indeterminate />
		</template>

		<template #default="{data,reload}">
			<v-form ref="form">
				<v-simple-table dense>
					<thead>
					<tr>
						<th>Станция</th>
						<th>Адрес</th>
						<th>Предна дата</th>
						<th>Дни</th>
						<th></th>
						<th class="text-center">Дата</th>
						<th class="text-center">Час</th>
						<th class="text-center">Прод. (min)</th>
						<th>Имоти</th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(item, index) in data" :key="item.station_id">
						<td>{{item.station_N}}</td>
						<td>{{item.address}}</td>
						<td nowrap width="1%"><date-format :date="item.prev_otchet_date" /></td>
						<td nowrap width="1%">{{diff(item.prev_otchet_date, formData[index].otchet_date)}}</td>
						<td width="1%">
							<v-icon color="warning" :style="{
								visibility: conflicted[index] ? 'visible' : 'hidden',
							}">mdi-clock-alert</v-icon>
						</td>
						<td width="200"><BaseInput :disabled="readonly" type="date" v-model="formData[index].otchet_date" dense solo flat hide-details /></td>
						<td width="100">
							<TimeInput :disabled="readonly" v-model="formData[index].otchet_time" dense solo flat hide-details="auto"
								:rules="[Validators.pattern(/^\d?\d:\d\d$/, 'hh:mm')]"
							/>
						</td>
						<td width="100"><BaseInput :disabled="readonly" type="decimal" v-model="formData[index].otchet_minutes" dense solo flat /></td>
						<td>{{item.count_imot}}</td>
					</tr>
					</tbody>
					<tfoot v-if="!readonly">
					<tr>
						<td colspan="9" align="right" class="pa-2">
							<btn-loading-promise :click="()=>saveData()" @ready="onReady(reload)">
								<template #default="{click,loading}">
									<v-btn
										@click.stop="click"
										:loading="loading"
										color="primary"
									>Запис</v-btn>
								</template>
							</btn-loading-promise>
						</td>
					</tr>
					</tfoot>
				</v-simple-table>
			</v-form>
		</template>

	</data-loader>
</template>

<script>
import DataLoader from "@/ittijs/components/DataLoader.vue";
import BaseInput from "@/ittijs/Inputs/BaseInput.vue";
import IModelMixin from "@/ittijs/IModelMixin";
import Validators from "@/ittijs/Validators";
import {cloneDeep, debounce} from "lodash";
import BtnLoadingPromise from "@/ittijs/components/BtnLoadingPromise.vue";
import TimeInput from "@/ittijs/Inputs/TimeInput.vue";
import DateFormat from "@/ittijs/components/DateFormat.vue";
import moment from "moment";

/**
 * Return new array using the same indexes, with value 'true' for any index that conflicts with another
 * @param checker
 * @returns {boolean[]}
 */
function arrayConflicts(checker) {
	const result = [];
	for (let i = 0; i < this.length - 1; i++) {
		for (let j = i + 1; j < this.length; j++) {
			if (result[j]) continue;
			if (checker(this[i], this[j])) {
				result[i] = true;
				result[j] = true;
				break;
			}
		}
	}
	return result;
}

function timeToMins(time) {
	const mins = time.split(":");
	return parseInt(mins[0], 10) * 60 + parseInt(mins[1]);
}

export default {
	components: {DateFormat, TimeInput, BtnLoadingPromise, DataLoader, BaseInput},
	mixins: [IModelMixin],
	props: {
		utility: {},
		session: {},
		otchetnik: {},
		readonly: Boolean,
	},
	data(){
		return {
			data: null,
			formData: [],
			formDataDebounced: [],
		}
	},
	watch: {
		formData: {
			deep: true,
			handler: debounce(function(){
				this.formDataDebounced = cloneDeep(this.formData);
			}, 100),
		},
	},
	computed: {
		Validators() {
			return Validators
		},
		conflicted(){
			if (!this.formDataDebounced || !Array.isArray(this.formDataDebounced)) return [];
			return arrayConflicts.call(this.formDataDebounced,
				(a, b) => a.otchet_date === b.otchet_date
					&& a.otchet_time.length && b.otchet_time.length
					&& !(
						timeToMins(a.otchet_time??'') >= timeToMins(b.otchet_time??'') + parseInt(b.otchet_minutes)
						|| timeToMins(b.otchet_time??'') >= timeToMins(a.otchet_time??'') + parseInt(a.otchet_minutes)
					)
			);
		},
	},
	methods: {
		diff(d1, d2){
			if (!d1 || !d2) return '';
			return moment(d2).diff(d1, 'days');
		},
		async loadData(){
			return await this.imodel.fetch('getSchedule', {
				utility: this.utility,
				session: this.session,
				otchetnik: this.otchetnik,
			});
		},
		onLoaded(data) {
			this.formData = data.map(item => ({
				station_id: item.station_id,
				otchet_date: item.otchet_date,
				otchet_time: item.otchet_time,
				otchet_minutes: item.otchet_minutes,
			}));
		},
		async saveData(){
			if (!this.$refs.form.validate()) return;
			return await this.imodel.fetch('updateSchedule', {
				utility: this.utility,
				session: this.session,
				otchetnik: this.otchetnik,
			}, this.formData);
		},
		onReady(reload){
			this.$emit('reload');
			reload();
		},
	},
}
</script>