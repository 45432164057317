<template>
	<v-text-field v-bind="$attrs" v-on="$listeners" @focusin="isNumInput = false" @focusout="isNumInput = false" @keydown="keydown" @input="input">
		<slot v-for="(_, slot) in $slots" :name="slot" :slot="slot" />
		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
			<slot :name="slot" v-bind="scope"/>
		</template>
	</v-text-field>
</template>

<script>
export default {
	data(){
		return {
			isNumInput: false,
		};
	},
	methods: {
		keydown(event) {
			this.isNumInput = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key);
		},
		input(val){
			if (typeof val === 'string') {
				// automatically add a colon, but only if last key was a number (ignore backspace/del)
				if (this.isNumInput && val.match(/^\d\d$/)) {
					this.$nextTick(() => {
						this.$emit('input', val + ':');
					});
					return;
				}
				// if user tries to enter the colon themselves (resulting in ::), remove it
				if (val.match(/^\d::$/)) {
					this.$nextTick(() => {
						this.$emit('input', val.substr(0, 2));
					});
					return;
				}
				else if (val.match(/^\d\d::$/)) {
					this.$nextTick(() => {
						this.$emit('input', val.substr(0, 3));
					});
					return;
				}
			}
		}
	},
}
</script>